import React, { useState, useEffect } from "react";
import "./Hero.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import { Container } from "react-bootstrap";

const Hero = () => {
  // State to store the image URLs
  const [images, setImages] = useState([]);

  // Fetch the API data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://tarunika.backendapihub.com/product/product-swim');
        const data = await response.json();
        if (data && data.data) {
          // Assuming the first product in the response contains the image data
          const media = data.data[0]?.media || [];
          setImages(media);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="hero-wrapper"> 
      <Container fluid className="hero-container"> 
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false} 
          autoPlay={true}
          infiniteLoop={true}
          interval={2000}  
          transitionTime={500} 
        >
          {/* Dynamically render the images */}
          {images.map((image, index) => (
            <div key={index} className="hero-slide">
              <img src={`https://tarunika.backendapihub.com/images/${image}`} alt={`Hero ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Hero;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ViewSimilar.css";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const ViewSimilar = ({ products }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visibleProducts, setVisibleProducts] = useState(5);
  const productRef = React.useRef(null);
  const navigate = useNavigate();

  // Dynamically calculate product width
  const getProductWidth = () => productRef.current?.offsetWidth || 266;

  const totalWidth = getProductWidth() * products.length;

  const maxScroll = () => {
    return totalWidth - getProductWidth() * visibleProducts;
  };

  const updateVisibleProducts = () => {
    if (window.innerWidth < 768) {
      setVisibleProducts(1);
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
      setVisibleProducts(2);
    } else {
      setVisibleProducts(5);
    }
  };

  useEffect(() => {
    updateVisibleProducts();
    window.addEventListener("resize", updateVisibleProducts);
    return () => {
      window.removeEventListener("resize", updateVisibleProducts);
    };
  }, []);

  const scrollLeft = () => {
    setScrollPosition((prev) => Math.max(prev - getProductWidth() * visibleProducts, 0));
  };

  const scrollRight = () => {
    setScrollPosition((prev) => Math.min(prev + getProductWidth() * visibleProducts, maxScroll()));
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="view-similar-container">
      <div className="view-similar-header">
        <h3 className="section-headings">View Similar</h3>
        {products.length > visibleProducts && (
          <div className="slider-arrows">
            <IconButton onClick={scrollLeft} disabled={scrollPosition === 0}>
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={scrollRight} disabled={scrollPosition >= maxScroll()}>
              <ArrowForwardIos />
            </IconButton>
          </div>
        )}
      </div>
      <Box className="similar-grid-container" sx={{ overflow: "hidden" }}>
        <Box
          className="similar-grid"
          sx={{
            display: "flex",
            transform: `translateX(-${scrollPosition}px)`,
            transition: "transform 0.3s ease",
          }}
        >
          {products.map((product) => (
            <div
              className="similar-item"
              key={product.id}
              ref={productRef}
              onClick={() => handleProductClick(product.id)}
            >
              <img
                src={`https://tarunika.backendapihub.com/images/${product.medianame}`}
                alt={product.product_name}
              />
              <div className="product-details">
                <p className="view-product-name">{product.product_name}</p>
                <p className="view-product-price">Rs. {product.price.toFixed(2)}</p>
              </div>
            </div>
          ))}
        </Box>
      </Box>
    </div>
  );
};


export default ViewSimilar;

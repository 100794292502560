import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./SwimLanes.css";

const SwimLanes = () => {
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://tarunika.backendapihub.com/product/product-slider')
      .then(response => response.json())
      .then(data => {
        if (data && data.data && data.data.length > 0) {
          const slidersList = data.data.map(item => item.slider);
          setSliders(slidersList);
        }
      })
      .catch(error => console.error('Error fetching data:', error))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const handleProductClick = (l1_id) => {
    navigate(`/product/${l1_id}`);
  };

  return (
    <div className="festival-kurtas-container container-fluid">
      {sliders.map((slider) => (
        <div key={slider.id} className="mb-5">
          <h3 className="section-heading">{slider.name}</h3>
          <div className="row">
            {slider.products.map((product, index) => (
              <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
                <div className="kurta-item" onClick={() => handleProductClick(product.l1_id)}>
                  <img
                    src={`https://tarunika.backendapihub.com/images/${product.mediaName[0]}`}
                    alt={product.product_name}
                    className="img-fluid"
                  />
                  <div className="kurta-details">
                    <p className="kurta-name">{product.product_name}</p>
                    <p className="kurta-price">Rs. {product.price}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SwimLanes;

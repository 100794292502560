import React from 'react'
import Hero from './Hero'
import CategorySection from './CategorySection';
// import NewArrivals from './NewArrivals';
// import TradingCategories from './TradingCategories'; 
import ShopByCategory from './ShopByCategory';
import SwimLanes from './SwimLanes';

const SubHome = () => {
  return (
    <div style={{backgroundColor: "#790101"}}>
      <Hero />
      <CategorySection />
      {/* <NewArrivals/> */}
      <ShopByCategory />
      <SwimLanes/>
      {/* <TradingCategories /> */}
    </div>
  )
}

export default SubHome 
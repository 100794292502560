import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import Footer_logo from "../../Assets/gold.png";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

export const Footer = ({ showBothDivs }) => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="Footer-main bg-dark-red py-5">
      <div className="container">
        <div className="row">
          {/* Logo Section */}
          <div className="col-lg-3 col-md-6 mb-4 text-center">
            <img src={Footer_logo} alt="logo" width={154} />
          </div>

          {/* Navigation Links Section */}
          <div className="col-lg-3 col-md-6 mb-4">
            <h5 className="text-light text-uppercase">Quick Links</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/" className="footer-link" onClick={scrollToTop}>Home</NavLink></li>
              <li><NavLink to="/products" className="footer-link" onClick={scrollToTop}>Products</NavLink></li>
            </ul>
          </div>

          {/* Policies Section */}
          <div className="col-lg-3 col-md-6 mb-4">
            <h5 className="text-light text-uppercase">Policies</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/privacy-policy" className="footer-link" onClick={scrollToTop}>Privacy Policy</NavLink></li>
              <li><NavLink to="/return-refund-policy" className="footer-link" onClick={scrollToTop}>Return & Refund</NavLink></li>
              <li><NavLink to="/terms-conditions" className="footer-link" onClick={scrollToTop}>Terms & Conditions</NavLink></li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div className="col-lg-3 col-md-6 mb-4 text-center">
            <h5 className="text-light text-uppercase">Follow Us</h5>
            <div className="d-flex justify-content-center gap-4">
              <FontAwesomeIcon icon={faFacebook} size="2x" className="social-icon" />
              <FontAwesomeIcon icon={faInstagram} size="2x" className="social-icon" />
              <FontAwesomeIcon icon={faTwitter} size="2x" className="social-icon" />
            </div>
          </div>
        </div>

        {/* Horizontal Line Above Copyright */}
        <hr className="footer-line" />

        {/* Footer Bottom */}
        <div className="text-center mt-4">
          <p className="mb-0 text-light">&copy; 2024 Tarunika. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

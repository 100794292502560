import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import "./Login.css";
import logo from "../../Assets/gold.png";
import { FaTimes } from "react-icons/fa";
import {useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendTime, setResendTime] = useState(180);
  const [cartItems, setCartItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const previousPage = location.state?.from || null;

  useEffect(() => {
    if (isModalOpen) {
      setOtp(Array(6).fill(""));
      setResendTime(180);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isOtpSent && resendTime > 0) {
      const timer = setInterval(() => {
        setResendTime((prevTime) => (prevTime <= 1 ? 0 : prevTime - 1));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTime, isOtpSent]);

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
    setError("");
  };
  

  const handleOtpChange = (index, value) => {
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      } else if (index > 0 && !value) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      
    }
  };

  const handlePasteOtp = (e) => {
    const pastedData = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedData)) {
      const newOtp = pastedData.split('');
      setOtp(newOtp);
      // Focus the last input box
      document.getElementById(`otp-input-5`).focus();
    }
  };

  const handleSendOtp = async () => {

    if (!mobile) {
      setError("Please enter your mobile number.");
      return;
    }

    if (mobile.length !== 10 || !/^[0-9]+$/.test(mobile)) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }
  
    try {
      const url = isRegistering
        ? "https://tarunika.backendapihub.com/auth/sendOTP/register"
        : "https://tarunika.backendapihub.com/auth/sendOTP/login";
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setIsOtpSent(true);
        setIsModalOpen(true);
        setError(""); 
      } else {
        setError(result.message || "Failed to send OTP");
      }
    } catch (error) {
      setError("An error occurred while sending OTP");
    }
  };
  

  const handleResendOtp = () => {
    if (resendTime === 0) {
      handleSendOtp();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setError("");
  };

  const handleSubmitOtp = async () => {
    const url = isRegistering
      ? "https://tarunika.backendapihub.com/auth/verifyOTP/register"
      : "https://tarunika.backendapihub.com/auth/verifyOTP/login";
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile, otp: otp.join("") }),
      });
  
      const result = await response.json();
  
      // console.log("API Response:", response);
      // console.log("Parsed Result:", result);
  
      if (response.ok && result.status === "Success") {
  
        if (isRegistering) {
          setIsRegistering(false); 
          setIsOtpSent(false);
          setMobile("");
          setOtp(Array(6).fill(""));
          setIsModalOpen(false);
  
          toast.success("Registration successful! Please login to continue.");
        } else {
          const token = result.data[0]?.token;
          if (token) {
            sessionStorage.setItem("auth", token);
          }
  
          setOtp(Array(6).fill(""));
          setIsModalOpen(false);
  
          if (previousPage) {
            navigate(previousPage); // Go to the previous page
          } else {
            window.history.back();  // Use browser history to go back
          }
        }
      } else {
        setError(result.message || "Failed to verify OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("An error occurred while verifying OTP");
    }
  };
  
  const handleSwitchMode = () => {
    setIsRegistering((prev) => !prev);
    setIsOtpSent(false);
    setMobile("");
    setOtp(Array(6).fill(""));
    setError("");
    setIsModalOpen(false);
  };
  

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true}>
      <div className="login-top-section"></div>
      <div className="login-container">
        <div className="left-side">
          <img src={logo} alt="Logo" />
          <h2>{isRegistering ? "Register" : "Login"}</h2>
        </div>
        <div className="right-side">
          <h2>{isOtpSent ? "Verify With OTP" : isRegistering ? "Register" : "Login"}</h2>
          <div className="input-containerr">
            <input
              type="tel"
              value={mobile}
              onChange={handleMobileChange}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              onKeyDown={(e) => e.key === "Enter" && handleSendOtp()} 
              placeholder="Enter Your Mobile Number"
              className="mobile-input"
              maxLength="10"
              pattern="[0-9]*"
            />
            <button
              className="COntinue-btn"
              onClick={handleSendOtp}
              disabled={mobile.length !== 10 || isOtpSent}
            >
              {isRegistering ? "Register" : "Login"}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button className="switch-btn" onClick={handleSwitchMode}>
            {isRegistering ? "Existing User? Login" : "New to Tarunika? Create an account"}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="otp-modal">
          <div className="otp-modal-content">
            <FaTimes className="close-iconn" onClick={handleCloseModal} />
            <h2>Enter OTP</h2>
            {error && <p className="error-message">{error}</p>}
            <div className="otp-container">
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  maxLength="1"
                  className="otp-input"
                  onKeyDown={(e) => e.key === "Enter" && handleSubmitOtp()}
                  onPaste={handlePasteOtp}  
                />
              ))}
            </div>
            <div className="otp-actions">
              {resendTime > 0 ? (
                <span>Resend OTP in: {formatTime(resendTime)}</span>
              ) : (
                <span className="resend-linkk" onClick={handleResendOtp}>
                  Resend OTP
                </span>
              )}
              <button className="submit-otp-btnn" onClick={handleSubmitOtp}>
                Submit OTP
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Login;

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styles/ProductListing.css";
import Item from "../Components/Item";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";
import { Dropdown } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import Loader from "../Components/Layout/Loader";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Slider from "@mui/material/Slider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfiniteScroll from "react-infinite-scroll-component";

const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const accessToken = sessionStorage.getItem("auth");

export const ProductListing = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    category: [],
    price: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    category: [],
    price: [filterOptions.price[0]?.range[0], filterOptions.price[0]?.range[1]],
    l1_primary_filter: [],
    l2_primary_filter: [],
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortBy, setSortBy] = useState("price asc");
  const [isAnyFilterChecked, setIsAnyFilterChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();
  const { categoryID } = location.state || {};
  const [hasLoadedAllProducts, setHasLoadedAllProducts] = useState(false);
  const [noProductsMessage, setNoProductsMessage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openAccordions, setOpenAccordions] = useState({});
  const [initialPriceRange, setInitialPriceRange] = useState([0, 0]);
  const [isPriceAdjusted, setIsPriceAdjusted] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const isInitialMount = useRef(true);

  const updateUrlWithFilters = () => {
    const queryString = buildQuery(page); // Use the existing buildQuery function
    navigate(`/products${queryString}`); // Call navigate directly
  };

  useEffect(() => {
    updateUrlWithFilters();
  }, [selectedFilters, isPriceAdjusted, page]);

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchProducts = debounce(() => fetchProducts(), 300);

  useEffect(() => {
    if (!loading) {
      debouncedFetchProducts();
    }
  }, [page]);

  useEffect(() => {
    fetchProducts();
    fetchFilters();
  }, []);

  useEffect(() => {
    setPage(1);
    fetchProducts(1);
  }, [selectedFilters]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      debouncedFetchProducts();
    }
  }, [page, selectedFilters]);

  useEffect(() => {
    filterProducts();
  }, [selectedFilters]);

  useEffect(() => {
    handleSortChange(sortBy);
  }, [sortBy]);

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  useEffect(() => {
    if (categoryID) {
      setSelectedFilters((prevFilters) => {
        const updatedCategoryFilters = [...prevFilters.category];

        // Add categoryID if not already present
        const categoryIDString = categoryID.toString();
        if (!updatedCategoryFilters.includes(categoryIDString)) {
          updatedCategoryFilters.push(categoryIDString);
        }

        return {
          ...prevFilters,
          category: updatedCategoryFilters,
        };
      });
    }
  }, [categoryID]);

  const handleScroll = () => {
    const bottomReached =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 1;

    if (bottomReached && !loading && hasMore && !hasLoadedAllProducts) {
      setPage((prev) => prev + 1);
    }
  };

  const fetchProducts = async (currentPage = page) => {
    if (!hasMore && currentPage > 1) return;

    try {
      setLoading(true);
      if (currentPage === 1) {
        setHasMore(true);
      }
      const queryString = buildQuery(currentPage);
      const response = await axios.get(
        `https://tarunika.backendapihub.com/product/listview/products${queryString}`,
        {
          headers: {
            "access-token": accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      const { status, message, data, total } = response.data;

      if (
        status === "success" &&
        message === "No data found" &&
        data.length === 1 &&
        Object.keys(data[0]).length === 0
      ) {
        setHasMore(false);
        // setAllProduct([]);
        // setFilteredProducts([]);
        setNoProductsMessage(message);
        return;
      }

      if (data.length < 9) {
        setHasMore(false);
      }

      const products = data.map((product) => ({
        category_id: product.category_id,
        product_id: product.product_id,
        name: product.product_name,
        desc: product.product_description,
        l1: product.l1_id,
        l2_id: product.l2_id,
        size: product.size_id,
        image: product.image
          ? `https://tarunika.backendapihub.com/images/${product.image}`
          : "default_image_url",
        price: product.price ? product.price.toString() : "0.00",
        ram: product.l1_primary_filter,
        memory: product.l2_primary_filter,
      }));

      if (currentPage === 1) {
        setAllProduct(products); // Reset product list
        setFilteredProducts(products); // Reset filtered list
      } else {
        setAllProduct((prev) => [...prev, ...products]);
        setFilteredProducts((prev) => [...prev, ...products]);
      }
      setNoProductsMessage("");
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterOpen = (event) => {
    event.preventDefault();
    const filterAccordion = document.getElementById("filterAccordion");
    if (filterAccordion) {
      const isHidden = filterAccordion.style.display === "none";
      filterAccordion.style.display = isHidden ? "block" : "none";
    }
  };

  useEffect(() => {
    if (filterOptions.price.length > 0) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        price: [
          filterOptions.price[0].range[0],
          filterOptions.price[0].range[1],
        ],
      }));
    }
  }, [filterOptions]);

  useEffect(() => {
    const initialAccordions = {};

    Object.keys(filterOptions).forEach((key) => {
      initialAccordions[key] = true; // Set to true to keep them open
    });

    if (filterOptions.filter_data) {
      Object.keys(filterOptions.filter_data).forEach((key) => {
        initialAccordions[key] = true; // Open all filter_data by default
      });
    }

    setOpenAccordions(initialAccordions);
  }, [filterOptions]);

  const handleAccordionClick = (filterKey) => {
    setOpenAccordions((prevAccordions) => ({
      ...prevAccordions,
      [filterKey]: !prevAccordions[filterKey], // Toggle the current filter accordion
    }));
  };

  const fetchFilters = async () => {
    setLoadingFilters(true);
    try {
      const response = await axios.get(
        "https://tarunika.backendapihub.com/product/filter",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data.data || [];

      const filters = { category: [], price: [], filter_data: {} };

      let minPrice = Number.MAX_VALUE;
      let maxPrice = 0;

      data.forEach((categoryData) => {
        // Extract and print categories
        const categories = categoryData.category.map((item) => ({
          id: item.category_id,
          name: item.category_name,
        }));
        // console.log("Categories:", categories);
        filters.category.push(...categories);

        // Extract and process filter_data keys and values
        if (categoryData.filter_data) {
          Object.keys(categoryData.filter_data).forEach((key) => {
            if (!filters.filter_data[key]) {
              filters.filter_data[key] = [];
            }
            filters.filter_data[key].push(...categoryData.filter_data[key]);
          });
          // console.log("Filter Data:", filters.filter_data);
        }

        minPrice = Math.min(minPrice, categoryData.initial_price);
        maxPrice = Math.max(maxPrice, categoryData.end_price);

        filters.price.push({
          value: `₹${categoryData.initial_price} - ₹${categoryData.end_price}`,
          range: [categoryData.initial_price, categoryData.end_price],
        });
      });
      // console.log("Price Range: ₹" + minPrice + " - ₹" + maxPrice);
      setFilterOptions(filters);
      setInitialPriceRange([minPrice, maxPrice]);
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        price: [minPrice, maxPrice],
      }));
    } catch (error) {
      console.error("Error fetching filters:", error);
    } finally {
      setLoadingFilters(false);
    }
  };

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      // Get the filter data for the current filterKey
      const filterData = filterOptions.filter_data[filterKey] || [];
      const selectedFilter = filterData.find((item) => item.value === value);

      if (filterKey === "category") {
        // Toggle the selected category
        if (updatedFilters.category.includes(value)) {
          updatedFilters.category = updatedFilters.category.filter(
            (item) => item !== value
          );
        } else {
          updatedFilters.category.push(value);
        }
      }

      // Handle l1_primary_filter and l2_primary_filter separately
      if (selectedFilter) {
        const column = selectedFilter.column;

        // Add or remove the value based on its current state
        if (updatedFilters[column].includes(value)) {
          updatedFilters[column] = updatedFilters[column].filter(
            (item) => item !== value
          );
        } else {
          updatedFilters[column].push(value);
        }
      }
      filterProducts(); // Make sure to apply the filter right after updating the selectedFilters
      updateUrlWithFilters();

      return updatedFilters;
    });
  };

  useEffect(() => {
    const anyFiltersApplied =
      isPriceAdjusted ||
      selectedFilters.category.length > 0 ||
      selectedFilters.l1_primary_filter.length > 0 ||
      selectedFilters.l2_primary_filter.length > 0;

    setIsAnyFilterChecked(anyFiltersApplied);
  }, [selectedFilters, isPriceAdjusted]);

  useEffect(() => {
    const isPriceRangeDifferent =
      selectedFilters.price[0] !== initialPriceRange[0] ||
      selectedFilters.price[1] !== initialPriceRange[1];

    setIsPriceAdjusted(isPriceRangeDifferent);
  }, [selectedFilters.price, initialPriceRange]);

  const filterProducts = () => {
    let filtered = [...allProduct];

    if (selectedFilters.category && selectedFilters.category.length > 0) {
      filtered = filtered.filter((product) =>
        selectedFilters.category.includes(product.category_id)
      );
    }

    if (selectedFilters.price && selectedFilters.price.length > 0) {
      const [minPrice, maxPrice] = selectedFilters.price[0]?.range || [
        0, 999999,
      ];
      filtered = filtered.filter((product) => {
        const productPrice = formatPrice(product.price);
        return productPrice >= minPrice && productPrice <= maxPrice;
      });
    }

    setFilteredProducts(filtered);
  };

  const formatPrice = (price) => {
    const parsedPrice = parseFloat(price.replace(/[^0-9.]/g, ""));
    return isNaN(parsedPrice) ? 0 : parsedPrice;
  };

  const clearAllFilters = () => {
    setSelectedFilters({
      category: [],
      price: [
        filterOptions.price[0]?.range[0],
        filterOptions.price[0]?.range[1],
      ],
      l1_primary_filter: [],
      l2_primary_filter: [],
    });
    setSortBy("price asc");
    setPage(1);
    setHasMore(true);
    setAllProduct([]);
    setFilteredProducts([]);
    fetchProducts();

    setIsAnyFilterChecked(false);

    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
    updateUrlWithFilters();
    // fetchProducts(1);
  };

  const handleSortChange = (sortOption) => {
    setSortBy(sortOption);
    setFilteredProducts((prevProducts) => {
      let sortedProducts = [...prevProducts];
      if (sortOption === "price desc") {
        sortedProducts.sort(
          (a, b) => formatPrice(b.price) - formatPrice(a.price)
        );
      } else if (sortOption === "price asc") {
        sortedProducts.sort(
          (a, b) => formatPrice(a.price) - formatPrice(b.price)
        );
      }
      return sortedProducts;
    });
  };

  const buildQuery = (currentPage) => {
    let query = `?page=${currentPage}&limit=9`;
    let filterConditions = [];

    if (selectedFilters.category.length > 0) {
      const categoryIds = selectedFilters.category
        .map((id) => `'${id}'`)
        .join(",");
      filterConditions.push(`category_id IN (${categoryIds})`);
    }

    if (
      selectedFilters.price.length > 0 &&
      selectedFilters.price[0] !== undefined
    ) {
      const [minPrice, maxPrice] = selectedFilters.price;
      filterConditions.push(`price >= ${minPrice} AND price <= ${maxPrice}`);
    }

    // Include l1_primary_filter if it has selected values
    if (selectedFilters.l1_primary_filter.length > 0) {
      const l1Filters = selectedFilters.l1_primary_filter
        .map((value) => `'${value}'`)
        .join(",");
      filterConditions.push(`l1_primary_filter IN (${l1Filters})`);
    }

    // Include l2_primary_filter if it has selected values
    if (selectedFilters.l2_primary_filter.length > 0) {
      const l2Filters = selectedFilters.l2_primary_filter
        .map((value) => `'${value}'`)
        .join(",");
      filterConditions.push(`l2_primary_filter IN (${l2Filters})`);
    }

    if (filterConditions.length > 0) {
      query += `&filter=${encodeURIComponent(filterConditions.join(" AND "))}`;
    }

    return query;
  };

  const handlePriceSliderChange = (event, newValue) => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, price: newValue }));
    updateUrlWithFilters();
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="top-section"></div>
      <div className="product-listing">
        <div className="productlisting-header container d-flex justify-content-center align-items-center flex-column">
          <h6>CELEBRATE THE FESTIVE SPIRIT IN STYLE</h6>
          <h2>All products</h2>
        </div>
      </div>
      <div className="productlisting-indexSort container d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between  mb-3">
        <div className="d-flex justify-content-between w-100">
          <button
            className="btn-filter filter-btn ms-2 mb-3 mb-sm-0"
            onClick={toggleSidebar}
            aria-label="Open Filters"
          >
            <div className="filter-container d-flex align-items-center">
              <h6 className="filter">FILTERS</h6>
              <FilterAltIcon fontSize="small" className="fillicon" />
            </div>
          </button>
          <button
            className="clear-all-btn btn mb-3 mb-sm-0"
            onClick={clearAllFilters}
            style={{ display: isAnyFilterChecked ? "block" : "none" }}
          >
            Clear All
          </button>
          <div className="productlisting-sort ms-sm-2">
            <div className="btn-group custom-btn-group">
              <Dropdown onToggle={handleDropdownToggle}>
                <Dropdown.Toggle className="btn custom-btn1" id="sortDropdown">
                  Sort by:{" "}
                  {sortBy === "price desc" ? "High to Low" : "Low to High"}{" "}
                  {/* Arrow Icon */}
                  <span>
                    {dropdownOpen ? (
                      <i className="bi bi-chevron-up"></i>
                    ) : (
                      <i className="bi bi-chevron-down"></i>
                    )}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSortChange("price desc")}>
                    Price: High to Low
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortChange("price asc")}>
                    Price: Low to High
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className="product-list notranslate">
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-3 col-sm-12 ${
                sidebarOpen ? "d-block" : "d-none"
              } d-lg-block`}
            >
              <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
                <div
                  className={`accordion ${loadingFilters ? "loading" : ""}`}
                  id="filterAccordion"
                >
                  {loadingFilters ? (
                    <div className="loader">
                      {" "}
                      {/* Replace with your loader component */}
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <button
                        className="btn-close-filter"
                        onClick={toggleSidebar}
                        aria-label="Close Filter"
                      >
                        <i className="bi bi-x-circle"> CLOSE FILTER</i>{" "}
                      </button>

                      <div className="accordion-item">
                        <h2 className="category-top accordion-header">
                          <div
                            className="d-flex justify-content-between align-items-center accordion-header"
                            onClick={() => handleAccordionClick("category")}
                            aria-expanded={openAccordions["category"] || false}
                            style={{ cursor: "pointer", paddingTop: "10px" }}
                          >
                            <span>Categories</span>
                            {openAccordions["category"] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </div>
                        </h2>
                        <div
                          className={`accordion-collapse collapse ${
                            openAccordions["category"] ? "show" : ""
                          }`}
                        >
                          <div className="accordion-body">
                            <ul className="list-unstyled">
                              {filterOptions.category.map((item, idx) => {
                                const isChecked =
                                  selectedFilters.category.includes(
                                    item.id.toString()
                                  ); // Convert item.id to string
                                return (
                                  <li key={idx} style={{ paddingTop: "10px" }}>
                                    <input
                                      type="checkbox"
                                      id={`category-${item.id}`}
                                      value={item.id}
                                      checked={isChecked}
                                      onChange={() =>
                                        handleFilterChange(
                                          "category",
                                          item.id.toString()
                                        )
                                      }
                                      style={{ accentColor: "#ffd404" }}
                                    />
                                    <label
                                      htmlFor={`category-${item.id}`}
                                      style={{ paddingLeft: "8px", textTransform: "capitalize" }}
                                    >
                                      {" "}
                                      {item.name}{" "}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <hr className="full-width-line" />

                      {Object.keys(filterOptions.filter_data || {}).map(
                        (filterKey) => (
                          <div key={filterKey} className="accordion-item">
                            <h2 className="accordion-header">
                              <div
                                className="d-flex justify-content-between align-items-center accordion-header"
                                type="button"
                                onClick={() => handleAccordionClick(filterKey)}
                                aria-expanded={
                                  openAccordions[filterKey] || false
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <span>{capitalizeWords(filterKey)}</span>
                                {openAccordions[filterKey] ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </div>
                            </h2>
                            <div
                              className={`accordion-collapse collapse ${
                                openAccordions[filterKey] ? "show" : ""
                              }`}
                            >
                              <div className="accordion-body">
                                <ul className="list-unstyled">
                                  {(
                                    filterOptions.filter_data[filterKey] || []
                                  ).map((item, idx) => (
                                    <li
                                      key={idx}
                                      style={{ paddingTop: "10px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        value={item.value}
                                        onChange={() =>
                                          handleFilterChange(
                                            filterKey,
                                            item.value
                                          )
                                        }
                                        style={{ accentColor: "#ffd404" }}
                                      />
                                      <span style={{ paddingLeft: "8px", textTransform: "capitalize" }}>
                                        {item.value}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <hr  className="full-width-line" />
                          </div>
                        )
                      )}

                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <div
                            className="d-flex justify-content-between align-items-center accordion-header"
                            type="button"
                            onClick={() => handleAccordionClick("price")}
                            aria-expanded={openAccordions["price"] || false}
                            style={{ cursor: "pointer" }}
                          >
                            <span>Price</span>
                            {openAccordions["price"] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </div>
                        </h2>
                        <div
                          className={`accordion-collapse collapse ${
                            openAccordions["price"] ? "show" : ""
                          }`}
                        >
                          <div className="accordion-body">
                            <div className="price-slider">
                              <label>
                                ₹{selectedFilters.price[0]} - ₹
                                {selectedFilters.price[1]}
                              </label>
                              <Slider
                                value={selectedFilters.price}
                                onChange={handlePriceSliderChange}
                                valueLabelDisplay="auto"
                                min={filterOptions.price[0]?.range[0] || 0}
                                max={filterOptions.price[0]?.range[1] || 10000}
                                disableSwap
                                sx={{ color: "#ffd404" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Product Listings */}
            <div
              className="col-lg-9 col-md-12 notranslate"
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                boxSizing: "border-box",
              }}
            >
              <InfiniteScroll
                dataLength={filteredProducts.length}
                next={() => {
                  if (hasMore && filteredProducts.length > 0) {
                    setPage((prevPage) => prevPage + 1);
                  }
                }}
                hasMore={hasMore}
                loader={<Loader />}
                scrollableTarget="scrollable-container"
              >
                <div
                  className="row justify-content-start"
                  style={{ display: "flex", flexWrap: "wrap", margin: "0" }}
                >
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                      <div
                        key={`${product.product_id}-${index}`}
                        className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4"
                      >
                        <Item
                          {...product}
                          img={product.image}
                          setCartItems={setCartItems}
                          cartItems={cartItems}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="no-data-container">
                      <p className="no-data-text">No products found.</p>
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default ProductListing;

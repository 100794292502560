import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../Assets/image_not_found.svg";
import "./CategorySection.css";
import 'bootstrap/dist/css/bootstrap.min.css'; 

const CategorySection = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://tarunika.backendapihub.com/product/get/categories", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setCategories(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleCategoryClick = (categoryID) => {
    navigate(`/products`, { state: { categoryID } }); 
  };

  return (
    <div className="category-section-container container">
      <h3 className="section-headingg">Shop by Category</h3>
      <div className="row justify-content-center dd">
        {categories.slice(0, 4).map((category) => {
          const imageUrl =
            category.media && category.media.length > 0
              ? `https://tarunika.backendapihub.com/images/${category.media[0]}`
              : defaultImage;

          return (
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 text-center"
              key={category.categoryID}
              onClick={() => handleCategoryClick(category.categoryID)}
              style={{ cursor: "pointer" }} 
            >
              <div className="bubble_item text-center">
                <img
                  src={imageUrl}
                  alt={`${category.category_name} Category`}
                  className="bubble_image img-fluid"
                  onError={(e) => {
                    e.target.src = defaultImage;
                  }}
                />
                <div className="bubble_text">{category.category_name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategorySection;

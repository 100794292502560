import { Routes,Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/User/Dashboard";
import Orders from "./Pages/User/Orders";
import ProductDetails from "./Pages/ProductDetails";
import CartPage from "./Pages/CartPage";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Wishlist from "./Pages/User/Wishlist";
import ProductPage from "./Pages/ProductList";
import CheckoutPage from "./Pages/CheckoutPage";
import PrivacyPolicy from "./Components/Sub_Home/PrivacyPolicy";
import RefundReturnPolicy from "./Components/Sub_Home/RefundReturnPolicy";
import TermsOfService from "./Components/Sub_Home/TermsOfService";
import ShippingPolicy from "./Components/Sub_Home/ShippingPolicy";
import ContactUs from "./Components/Sub_Home/ContactUs";
import PaymentFailurePage from "./Pages/PaymentFailurePage";
import PaymentSuccessPage from "./Pages/PaymentSuccessPage";
import OrderConfirmedPage from "./Pages/OrderConfirmedPageCod";



function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccessPage/>} />
        <Route path="/PaymentFailure" element={<PaymentFailurePage />} />
        <Route path="/order-confirmed" element={<OrderConfirmedPage />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/cart" element={<CartPage />} />

        <Route
            path="/dashboard"
            // element={<PrivateRoute />}
          >
            <Route path="user/profile" element={<Dashboard />} />
            <Route path="user/orderhistory" element={<Orders />} />
            <Route path="user/wishlist" element={<Wishlist />} />
          </Route>

        
        <Route path="/login" element={<Login />} />
        <Route path="/checkout" element={<CheckoutPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/return-refund-policy" element={<RefundReturnPolicy/>} />
        <Route path="/terms-conditions" element={<TermsOfService/>} />
        <Route path="/shipping-policy" element={<ShippingPolicy/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
      </Routes>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = Yup.object().shape({
  address1: Yup.string().trim().required("Required"),
  address2: Yup.string().trim().required("Required"),
  city: Yup.string().trim().required("Required"),
  stateId: Yup.number().required("Required"),
  pin: Yup.number()
    .nullable()
    .required("Required")
    .min(100000, "Invalid Pin")
    .max(999999, "Invalid Pin"),
});

const defaultValues = {
  address1: "",
  address2: "",
  city: "",
  stateId: null,
  pin: "",
};

const accessToken = sessionStorage.getItem("auth");

export default function AddAddressPopup({ open, onClose, onAddAddress }) {
  const [states, setStates] = useState([]);
  const [deliveryError, setDeliveryError] = useState("");

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { handleSubmit, reset, control, setValue, watch } = methods;

  const onSubmit = async (formData) => {

    setDeliveryError("");

    try {
      const pincodeResponse = await fetch(
        `https://tarunika.backendapihub.com/address/getPincode?pincode=${formData.pin}`,
        {
          method: "GET",
          headers: {
            "access-token": accessToken,
          },
        }
      );
  
      if (!pincodeResponse.ok) {
        setDeliveryError("Delivery is not available at this pincode.");
        return;
      }
  
      const pincodeResult = await pincodeResponse.json();
      if (
        pincodeResult.status.toLowerCase() !== "success" ||
        pincodeResult.message !== "Pincode found"
      ) {
        setDeliveryError("Delivery is not available at this pincode.");
        return;
      }
    } catch (error) {
      console.error("Error validating pincode:", error);
      alert("Error validating pincode. Please try again.");
      return;
    }
  
    // Proceed to save the address if pincode is valid
    const payload = {
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      stateId: formData.stateId,
      pincode: formData.pin,
    };
  
    try {
      const response = await fetch(
        "https://tarunika.backendapihub.com/address/add",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "access-token": accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        onAddAddress(result); 
        onClose(); 
      } else {
        setDeliveryError("Failed to save the address. Please try again.");
      }
    } catch (error) {
      console.error("Error adding address:", error);
      setDeliveryError("Error saving address. Please try again.");
    }
  };
  

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
      setDeliveryError("");
    }
  }, [open, reset]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await fetch(
          "https://tarunika.backendapihub.com/address/get/state",
          {
            method: "POST",
            headers: {
              accept: "application/json",
              "access-token": accessToken,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ limit: "100", page: "1" }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setStates(
            result.data.map((state) => ({
              id: state.ID,
              name: state.stateName,
            }))
          );
        } else {
          console.error("Failed to fetch states:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "700",
            fontFamily: "Kingred Modern",
            textTransform: "uppercase",
            color: "#535766",
          }}
        >
          Add New Address
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="address1"
                label="Address Line 1"
                multiline
                maxRows={2}
                fullWidth
                {...methods.register("address1")}
                error={Boolean(methods.formState.errors.address1)}
                helperText={methods.formState.errors.address1?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="address2"
                label="Address Line 2"
                multiline
                maxRows={2}
                fullWidth
                {...methods.register("address2")}
                error={Boolean(methods.formState.errors.address2)}
                helperText={methods.formState.errors.address2?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="city"
                label="City"
                fullWidth
                {...methods.register("city")}
                error={Boolean(methods.formState.errors.city)}
                helperText={methods.formState.errors.city?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="stateId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={states}
                    getOptionLabel={(option) => option.name}
                    value={
                      states.find((state) => state.id === field.value) || null
                    }
                    onChange={(event, value) => {
                      // When a new state is selected, set the `stateId` in the form
                      setValue("stateId", value ? value.id : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        error={Boolean(methods.formState.errors.stateId)}
                        helperText={methods.formState.errors.stateId?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="pin"
                label="Pincode"
                fullWidth
                inputProps={{ maxLength: 6 }}
                {...methods.register("pin")}
                error={Boolean(methods.formState.errors.pin) || Boolean(deliveryError)}
                helperText={
                  <Typography
                    sx={{
                      color: (methods.formState.errors.pin || deliveryError) ? "red" : "inherit",
                    }}
                  >
                    {methods.formState.errors.pin?.message || deliveryError}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <DialogActions sx={{ mt: 5 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#ffd404",
                color: "black",
                "&:hover": { backgroundColor: "#ffd404" },
                fontFamily: "Kingred Modern",
              }}
            >
              ADD ADDRESS
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={{
                backgroundColor: "white",
                color: "black",
                "&:hover": { backgroundColor: "#f0f0f0" },
                fontFamily: "Kingred Modern",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
